// import client from '../utils/api/client';
// import config from '../../config';

import BaseService from './Base';
import client from '@/lib/utils/api/client';
import config from '@/config';

// NotesService
class Notes extends BaseService {
  static BASE_URL = `/notes`;

  static async getNote(id) {
    try {
      let url = `${this.BASE_URL}/${id}`;
      const response = await client.get(url, {
        headers: { Authorization: `Bearer ${Notes.token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching notes:', error);
      throw error;
    }
  }

  // fetchNotes
  static async getNotes(options = {}) {
    try {
      let url = `${this.BASE_URL}/get`;
      if (options.sort) {
        url += `?sort=${options.sort}`;
      }
      const data = { tags: options?.tags };
      const response = await client.post(url, data, {
        headers: { Authorization: `Bearer ${Notes.token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching notes:', error);
      throw error;
    }
  }

  static async createNote(data) {
    const { content, tags = [], metadata = {}, order, userId, sort } = data;

    try {
      const response = await client.post(
        this.BASE_URL,
        { content, tags, metadata, order },
        {
          headers: { Authorization: `Bearer ${Notes.token}` },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error creating note:', error);
      throw error;
    }
  }

  static async updateNote(id, data) {
    const { content, tags, metadata } = data;

    try {
      const response = await client.put(
        `${this.BASE_URL}/${id}`,
        { content, tags, metadata },
        {
          headers: { Authorization: `Bearer ${Notes.token}` },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error updating note:', error);
      throw error;
    }
  }

  static async deleteNote(id) {
    try {
      const response = await client.delete(`${this.BASE_URL}/${id}`, {
        headers: { Authorization: `Bearer ${Notes.token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting note:', error);
      throw error;
    }
  }

  static async sortNotes(notes) {
    const noteOrderArr = notes.map((note, index) => ({ id: note.id, order: index }));
    try {
      const response = await client.post(
        `${this.BASE_URL}/sort`,
        { noteOrderArr },
        {
          headers: { Authorization: `Bearer ${Notes.token}` },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error deleting note:', error);
      throw error;
    }
  }

  static async searchNotes(term) {
    try {
      // todo: Add filter and sort too.
      const response = await client.get(`${this.BASE_URL}/search?q=${term}`, {
        headers: { Authorization: `Bearer ${Notes.token}` },
      }); // Or `${this.BASE_URL}?q=${searchTerm}` or `${this.BASE_URL}/search?q=${searchTerm}`.
      return response.data;
    } catch (error) {
      console.error('Error searching notes:', error);
      throw error;
    }
  }

  static exportNotes = async () => {
    try {
      const response = await client.post(
        `${this.BASE_URL}/export`,
        {},
        {
          headers: { Authorization: `Bearer ${Notes.token}` },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error exporting notes:', error);
      throw error;
    }
  };

  static importNotes = async () => {
    try {
      const response = await client.post(
        `${this.BASE_URL}/import`,
        {},
        {
          headers: { Authorization: `Bearer ${Notes.token}` },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error importing notes:', error);
      throw error;
    }
  };

  static anotherFunction = async () => {
    // ...
  };
}

export default Notes;
