// Could also be called store.js.
// Other possible subdirectories inside store - actions, reducers, selectors, features, etc.
import { configureStore, combineReducers, combineSlices } from '@reduxjs/toolkit';
import appReducer from './slices/appSlice';
import notesReducer from './slices/notesSlice';

const store = {
  reducer: {
    app: appReducer, // Define a top-level state field named `app`, handled by `appReducer`.
    notes: notesReducer,
  },
};

export default configureStore(store);

export const makeStore = () => {
  return configureStore(store);
};

// Infer the type of makeStore.
export type AppStore = ReturnType<typeof makeStore>;

// Infer the `RootState` and `AppDispatch` types from the store itself.
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
