import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import NotesService from '../../lib/services/Notes';

// Define API service base URL.
const BASE_URL = 'http://your-api-url.com/api/notes';

// Async thunk for adding a note.
export const addNote = createAsyncThunk('notes/addNote', async (noteData, { rejectWithValue }) => {
  const { content } = noteData;
  try {
    // const response = await axios.post(`${BASE_URL}`, noteData);
    const newNote = await NotesService.createNote(content);
    // return response.data;
    return newNote; // Assuming the API responds with the added note.
  } catch (error) {
    // return rejectWithValue(error.response.data);
    return rejectWithValue(error);
  }
});

// Async thunk for updating a note.
export const updateNote = createAsyncThunk(
  'notes/updateNote',
  async (noteData, { rejectWithValue }) => {
    const { id, content, tags } = noteData;
    try {
      // const response = await axios.put(`${BASE_URL}/${noteData.id}`, noteData);
      const updatedNote = await NotesService.updateNote(id, { content, tags });
      // return response.data;
      return updatedNote; // Assuming the API responds with the updated note.
    } catch (error) {
      // return rejectWithValue(error.response.data);
      return rejectWithValue(error);
    }
  },
);

// Async thunk for deleting a note.
export const deleteNote = createAsyncThunk(
  'notes/deleteNote',
  async (noteId, { rejectWithValue }) => {
    try {
      // await axios.delete(`${BASE_URL}/${noteId}`);
      await NotesService.deleteNote(noteId);
      return noteId; // Return the id of the deleted note
    } catch (error) {
      // return rejectWithValue(error.response.data);
      return rejectWithValue(error);
    }
  },
);

// searchNotes async thunk.
export const searchNotes = createAsyncThunk(
  'notes/searchNotes',
  async (searchTerm, { rejectWithValue }) => {
    try {
      const notes = await NotesService.searchNotes(searchTerm);
      return notes;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setInfoStatus = createAsyncThunk(
  'notes/setInfoStatus',
  async (statusData, { dispatch }) => {
    dispatch(notesSlice.actions.storeInfoStatus(statusData));
    setTimeout(() => {
      dispatch(notesSlice.actions.resetInfoStatus());
    }, 1500);
  },
);

// todo: Extract to weatherSlice.js and combine with appSlice.
export const fetchWeather = createAsyncThunk('weather/fetchWeather', async (city) => {
  const response = await axios.get(
    `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=YOUR_API_KEY`,
  );
  return response.data;
});

export const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    count: 1000,
    showMenus: true,
    isLoading: false, // Global loading state. Could be called just `loading`.
    notification: null, // Global notification state.
    error: null, // Global error state.
    weatherData: null,
    infoStatus: null,
    // notes: [], // Currently use notes array, not notesState object.
    // A.k.a. searchState: {}. Not using notesState: {} for now.
    search: {
      defaultValue: null, // defaultSearchInputValue
      note: null, // Active note.
      isLoading: false, // Search loading state.
      notification: null, // Search notification state.
      error: null, // Search error state.
    },
    // User and/or system settings.
    settings: {
      theme: 'dark',
      language: 'en',
      notesSortDirection: 'desc',
      showNoteDate: false,
      noteView: 'list', // Note view mode - list or md.
    },
    treeView: {
      title: '',
      items: [],
      activeItem: null,
      activeItemType: null,
      activeTable: null,
    },
    footerStats: [],
    user: null,
    notes: {
      note: null,
      notes: [],
      blocks: [],
      blockToFocus: null,
      saveStatus: 'saved',
    },
  },
  reducers: {
    increment: (state) => {
      state.count += 1;
    },
    toggleShowMenus: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.showMenus = !state.showMenus;
    },
    updateSearchState: (state, action) => {
      state.search = { ...state.search, ...action.payload };
    },
    updateSettings: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setNotes: (state, action) => {
      const newData = {
        ...state.notes,
        ...action.payload,
      };
      // console.log('setNotes newData:', newData);
      state.notes = newData;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    storeInfoStatus: (state, action) => {
      state.infoStatus = action.payload;
    },
    resetInfoStatus: (state) => {
      state.infoStatus = null;
    },
    showLoadingStatus: (state, action) => {
      state.loadingStatus = true;
    },
    hideLoadingStatus: (state) => {
      state.loadingStatus = false;
    },
    setTreeView: (state, action) => {
      // console.log('setTreeView action:', action);
      state.treeView = {
        ...state.treeView,
        ...action.payload,
        items: action.payload.items.map((item) => {
          if (item.itemName === action.payload.activeItem) {
            return { ...item, isActive: true };
          }
          return {
            ...item,
            children: item.children.map((child) => {
              if (child.itemName === action.payload.activeItem) {
                return { ...child, isActive: true };
              }
              return { ...child, isActive: false };
            }),
            isActive: false
           };
        }),
      };
    },
    setFooterStats: (state, action) => {
      state.footerStats = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeather.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchWeather.fulfilled, (state, action) => {
        state.isLoading = false;
        state.weatherData = action.payload;
      })
      .addCase(fetchWeather.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'An error occurred';
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.notes.push(action.payload);
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        state.notes = state.notes.map((note) =>
          note.id === action.payload.id ? action.payload : note,
        );
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.notes = state.notes.filter((note) => note.id !== action.payload);
      })
      .addCase(searchNotes.fulfilled, (state, action) => {
        state.notes = action.payload;
      })
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload || 'Something went wrong.';
        },
      )
      .addMatcher(
        (action) => action.type.endsWith('/pending'),
        (state, action) => {
          state.isLoading = true;
        },
      );
  },
  // selectors: {},
});

// Action creators are generated for each case reducer function.
export const {
  increment,
  toggleShowMenus,
  updateSearchState,
  updateSettings,
  setNotes,
  setIsLoading,
  setNotification,
  setError,
  showLoadingStatus,
  hideLoadingStatus,
  setTreeView,
  setFooterStats,
  setUser,
} = notesSlice.actions;

export default notesSlice.reducer;
